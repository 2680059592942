<template>
  <v-container fluid class="pa-0">
    <portal-target v-if="!patientsLoading && enrolledPatients.length > 0 && showGraphs" name="inboxSummary" slim />

    <portal v-if="patientList && !inboxSnapshot" to="appBarContentLeft">
      <v-row class="flex-nowrap" align="center" justify="space-between" no-gutters>
        <span style="font-weight: bold">
          {{ patientList.title }}
        </span>
      </v-row>
    </portal>

    <inbox-summary v-if="patientList && !inboxSnapshot" :filteredArray="patientList.array" />
  </v-container>
</template>

<script>
import { mapState } from 'vuex'
import moment from 'moment-timezone'
import consts from '@/consts'
import { msToTime2 } from '@/helpers/time'
import HelperMixin from '@/core/mixins/HelperMixin'
import InboxSummary from '@/components/rpminbox/InboxSummary.vue'

export default {
  mixins: [HelperMixin],
  components: {
    InboxSummary,
  },
  data() {
    return {
      showMap: false,
      showStats: false,
      mapMenuX: 0,
      mapMenuY: 0,
      showMapContextMenu: false,
      contextMenuData: null,
      alertFilterOptions: [
        { text: 'Out of range', value: true },
        { text: 'Within range', value: false },
      ],
      options: {},
      pageCount: 0,
      page: 1,
      priorityFilter: false,
      newPatientFilter: false,
      selectedAlertFilter: null,
      selectedActivityOption: null,
      selectedQHPOption: null,
      searchInput: null,
      patientTableHeight: 0,
      selectedPatients: [],
      selectedConditions: [],
      selectedTags: [],
      selectedZips: [],
      msToTime2,
      inboxSnapshot: false,
      showGraphs: true,
    }
  },
  beforeMount() {
    if (localStorage['_tryEnhancedView']) {
      this.inboxSnapshot = true
    }
  },
  mounted() {
    this.patientTableHeight = window.innerHeight - 280

    const vm = this
    window.onresize = function () {
      vm.patientTableHeight = window.innerHeight - 280
      vm.renderComponent = false

      vm.$nextTick(() => {
        // Add the component back in
        vm.renderComponent = true
      })
    }
  },
  watch: {
    patientsLoading(val) {
      this.showGraphs = false
      if (!val && this.org) {
        let vm = this

        setTimeout(() => {
          vm.showGraphs = true
        }, 200)
      }
    },
  },
  computed: {
    ...mapState('org', ['org', 'patientsLoading', 'enrolledPatients', 'enrolledPatientsZips']),
    ...mapState('inbox', ['patientList']),
    numberOfItemsPerRow() {
      return parseInt((this.patientTableHeight - 60) / 70)
    },
    patientsWithUnseenAlerts() {
      let filteredList = this.enrolledPatients

      filteredList = filteredList.filter(function (e) {
        return e.rpm.newAlerts
      })

      return filteredList
    },
    filterApplied() {
      return (
        this.priorityFilter ||
        this.newPatientFilter ||
        this.selectedConditions.length > 0 ||
        this.selectedTags.length > 0 ||
        this.selectedAlertFilter !== null ||
        this.selectedZips.length > 0 ||
        this.selectedActivityOption !== null ||
        this.selectedQHPOption !== null ||
        this.searchInput !== null
      )
    },
    METRICS() {
      return consts.METRICS
    },
    filteredPatients() {
      const vm = this
      const filteredList = this.enrolledPatients.filter(pt => {
        // Filter out non-priority patients.
        if (vm.priorityFilter && !pt.highPriority) {
          return false
        }

        // Filter out non-new patients.
        if (vm.newPatientFilter && !pt.newPatient) {
          return false
        }

        // Filter out patients that have no matching conditions.
        if (
          vm.selectedConditions.length > 0 &&
          !pt.conditionsForMatching.some(r => vm.selectedConditions.indexOf(r) >= 0)
        ) {
          return false
        }

        // Filter out patients that have no matching tags.
        if (vm.selectedTags.length > 0 && !pt.tags.some(r => vm.selectedTags.indexOf(r) >= 0)) {
          return false
        }

        // Filter based on alert status.
        if (vm.selectedAlertFilter) {
          if (vm.selectedAlertFilter.value && !pt.alert) {
            // Filter out patients not in alert.
            return false
          }
          if (!vm.selectedAlertFilter.value && pt.alert) {
            // Filter out patients in alert.
            return false
          }
        }

        // Filter out patients that don't live in given zip codes.
        if (vm.selectedZips.length > 0 && vm.selectedZips.indexOf(pt.zip) < 0) {
          return false
        }

        // Filter based on activity.
        if (vm.selectedActivityOption) {
          const opt = vm.selectedActivityOption
          if (pt.mostRecentDataTimestamp) {
            if (pt.mostRecentDataTimestamp < opt.lower || pt.mostRecentDataTimestamp > opt.upper) {
              // Most recent data occurred outside of range.
              return false
            }
          } else if (pt.rpm.enrollment.enrolled) {
            // No data, but enrolled. Patient can still be included in "Patient HASN'T taken a reading since X."
            // if patient was enrolled before X.
            const enrollmentStart = Date.parse(pt.rpm.enrollment.start)
            if (opt.lower !== -Infinity || enrollmentStart > opt.upper) {
              // "Patient HASN'T taken..." options have `opt.lower === -Infinity`. Filter out.
              // `enrollmentStart > opt.upper` means that this patient was enrolled more recently than X. Filter out.
              return false
            }
          } else {
            // No data and not enrolled (shouldn't reach here since we filtered down to enrolled earlier).
            return false
          }
        }

        // Filter based on QHP time.
        if (vm.selectedQHPOption) {
          const ms = pt.millisecondsThisPeriod || 0
          if (ms > vm.selectedQHPOption.upper || ms < vm.selectedQHPOption.lower) {
            return false
          }
        }

        // Filter by search.
        if (vm.searchInput) {
          let match =
            (pt.smsPhone && pt.smsPhone.replace(/[^0-9]/g, '').includes(vm.searchInput)) ||
            pt.lastName.toLowerCase().includes(vm.searchInput.toLowerCase()) ||
            pt.firstName.toLowerCase().includes(vm.searchInput.toLowerCase()) ||
            (pt.org.patientId && pt.org.patientId.toLowerCase().includes(vm.searchInput.toLowerCase()))
          if (!match) {
            return false
          }
        }

        return true
      })

      return filteredList
    },
    patientConditions() {
      let arrayOfArrayOfConditions = this.enrolledPatients.map(a => a.conditions)
      let conditionsWithoutDuplicates = []
      let icd10Codes = []
      arrayOfArrayOfConditions.forEach(patientConditions => {
        patientConditions.forEach(condition => {
          if (!icd10Codes.includes(condition.split('|')[0])) {
            icd10Codes.push(condition.split('|')[0])
            conditionsWithoutDuplicates.push({
              value: condition.split('|')[0],
              text: condition.split('|')[0] + ' ' + condition.split('|')[1],
            })
          }
        })
      })

      conditionsWithoutDuplicates.sort(function (a, b) {
        return a.value - b.value
      })

      return conditionsWithoutDuplicates
    },

    patientTags() {
      let arrayOfArrayOfTags = this.enrolledPatients.map(a => a.tags)

      let tags = []
      arrayOfArrayOfTags.forEach(patientTags => {
        patientTags.forEach(tag => {
          if (!tags.includes(tag)) {
            tags.push(tag)
          }
        })
      })
      return tags.sort()
    },
    qhpOptions() {
      let options = []
      options.push({
        text: 'Zero chart time',
        lower: 0,
        upper: 0,
        selectionText: 'Zero chart time',
        icon: 'mdi-clock-check-outline',
        iconColor: 'primary',
        zero: true,
      })
      options.push({
        text: 'Fewer than 10 minutes',
        lower: 1,
        upper: 60000 * 10 - 1,
        selectionText: 'Fewer than 10 min',
        icon: 'mdi-clock-check-outline',
        iconColor: 'primary',
        range: true,
      })
      options.push({
        text: 'Between 10 and 20 minutes',
        lower: 60000 * 10,
        upper: 60000 * 20 - 1,
        selectionText: 'Between 10 and 20 minutes',
        icon: 'mdi-clock-check-outline',
        iconColor: 'primary',
        range: true,
      })
      options.push({
        text: 'Fewer than 20 minutes',
        lower: 1,
        upper: 60000 * 20 - 1,
        selectionText: 'Fewer than 20 Min',
        icon: 'mdi-clock-alert-outline',
        range: true,
      })
      options.push({
        text: '20 minutes or more',
        lower: 60000 * 20,
        upper: Infinity,
        selectionText: '20 Min+',
        icon: 'mdi-clock-check-outline',
        iconColor: 'primary',
      })

      return options
    },
    activityOptions() {
      const options = []
      // get the start of the day
      const startOfToday = new Date()
      startOfToday.setHours(0, 0, 0, 0)
      const endOfToday = new Date(startOfToday)
      endOfToday.setDate(endOfToday.getDate() + 1)
      endOfToday.setMilliseconds(endOfToday.getMilliseconds() - 1)
      options.push({
        text: 'Took a reading today',
        lower: startOfToday.getTime(),
        upper: endOfToday.getTime(),
        selectionText: 'Today',
        icon: 'mdi-calendar-check',
        iconColor: 'primary',
      })
      options.push({
        text: "Didn't take a reading today",
        lower: -Infinity,
        upper: startOfToday.getTime(),
        selectionText: 'Today',
        icon: 'mdi-calendar-remove',
      })

      const startOfYesterday = new Date(startOfToday.valueOf())
      startOfYesterday.setDate(startOfYesterday.getDate() - 1)
      options.push({
        text: 'Took a reading today or yesterday',
        lower: startOfYesterday.getTime(),
        upper: endOfToday.getTime(),
        selectionText: 'Since yesterday',
        icon: 'mdi-calendar-check',
        iconColor: 'primary',
      })
      options.push({
        text: "Didn't take a reading today or yesterday",
        lower: -Infinity,
        upper: startOfYesterday.getTime(),
        selectionText: 'Since yesterday',
        icon: 'mdi-calendar-remove',
      })

      const sevenDaysAgo = new Date(startOfToday.valueOf())
      sevenDaysAgo.setDate(sevenDaysAgo.getDate() - 7)
      options.push({
        text: 'Took a reading in the last 7 days',
        lower: sevenDaysAgo.getTime(),
        upper: endOfToday.getTime(),
        selectionText: '7 Days',
        icon: 'mdi-calendar-check',
        iconColor: 'primary',
      })
      options.push({
        text: "Hasn't taken a reading in 7+ days",
        lower: -Infinity,
        upper: sevenDaysAgo.getTime(),
        selectionText: '7+ Days',
        icon: 'mdi-calendar-remove',
      })

      const thirtyDaysAgo = new Date(startOfToday.valueOf())
      thirtyDaysAgo.setDate(thirtyDaysAgo.getDate() - 30)
      options.push({
        text: 'Took a reading in the last 30 days',
        lower: thirtyDaysAgo.getTime(),
        upper: endOfToday.getTime(),
        selectionText: '30 Days',
        icon: 'mdi-calendar-check',
        iconColor: 'primary',
      })
      options.push({
        text: "Hasn't taken a reading in 30+ days",
        lower: -Infinity,
        upper: thirtyDaysAgo.getTime(),
        selectionText: '30+ Days',
        icon: 'mdi-calendar-remove',
      })

      return options
    },
  },
  methods: {
    sendMassText() {
      this.$refs.massText.open()
    },
    zipCodeSelected(zipHere) {
      let match = this.enrolledPatientsZips.find(zip => zip.text === zipHere)
      if (match) {
        this.selectedZips = [match.text]
      }
    },
    hoverOverMapPoint(event) {
      this.mapMenuX = event.x
      this.mapMenuY = event.y
      this.showMapContextMenu = true
      this.contextMenuData = event.data
    },
    cancelHoverOverMapPoint() {
      this.mapMenuX = null
      this.mapMenuY = null
      this.showMapContextMenu = null
      this.contextMenuData = null
    },
    applyConditionFilter(condition) {
      const index = this.selectedConditions.indexOf(condition.title.split(' ')[0])

      if (index > -1) {
        this.selectedConditions.splice(index, 1)
      } else {
        this.selectedConditions.push(condition.title.split(' ')[0])
      }
    },
    manageTags() {
      let patientToPass = this.selectedPatients[0]
      const found = this.enrolledPatients.find(patient => patient.id === patientToPass.id)
      if (found) {
        this.$refs.tagManagerDialog.open({ patient: patientToPass })
      }
    },
    closeTagMenu() {
      setTimeout(() => {
        this.tagMenu = false
      }, 200)
    },
    toggleSelected() {
      if (this.selectedPatients.length > 0) {
        this.selectedPatients = []
      } else {
        this.selectedPatients = this.$refs.dataTable.$children[0].filteredItems
      }
    },
    removeAllFilters() {
      this.selectedConditions = []
      this.selectedTags = []
      this.selectedAlertFilter = null
      this.selectedZips = []
      this.selectedActivityOption = null
      this.selectedQHPOption = null
      this.priorityFilter = false
      this.newPatientFilter = false
    },
    removeSpecificConditionFilter(condition) {
      const index = this.selectedConditions.indexOf(condition)
      if (index > -1) {
        this.selectedConditions.splice(index, 1)
      }
    },
    removeSpecificZipFilter(zip) {
      const index = this.selectedZips.indexOf(zip)
      if (index > -1) {
        this.selectedZips.splice(index, 1)
      }
    },
    removeSpecificTagFilter(tag) {
      const index = this.selectedTags.indexOf(tag)
      if (index > -1) {
        this.selectedTags.splice(index, 1)
      }
    },
    relativeTime(value) {
      if (value) {
        let dt = moment(value).tz(moment.tz.guess()).format('MM/DD/YYYY h:mm a')
        if (dt.includes('12:00 am')) {
          return 'Today'
        } else {
          return moment(value).tz(moment.tz.guess()).fromNow()
        }
      }
      return ''
    },
    handleClick(patient) {
      this.$router.push({ name: 'PatientFeed', params: { id: patient.id } })
    },
  },
}
</script>

<style lang="css" scoped>
.row-pointer {
  cursor: pointer;
}
</style>

<style></style>
